import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import useAuthUser from "../hooks/getUser";
import {
	Button,
	Container,
	Header,
	Grid,
	// Segment,
	// TransitionablePortal,
} from "semantic-ui-react";

function Login() {
	const { authState, oktaAuth } = useOktaAuth();
	const userInfo = useAuthUser();

	const loginWithRedirect = () =>
		oktaAuth.signInWithRedirect({ originalUri: "/login" });

	const logOut = () => oktaAuth.signOut();

	const buttonText = authState.isAuthenticated ? "Logout" : "Login";
	const btnLogic = authState.isAuthenticated ? logOut : loginWithRedirect;

	return (
		<Container>
			{authState?.isAuthenticated ? (
				<h2>Welcome back, {userInfo?.name}</h2>
			) : (
				<p style={{ textAlign: "center", marginTop: "6rem", fontSize: "2rem" }}>
					Please login to see data
				</p>
			)}
			<Grid textAlign={"center"}>
				<Button basic color="olive" onClick={btnLogic}>
					{buttonText}
				</Button>
			</Grid>
		</Container>
	);
}

// class Login extends Component {
// 	state = { open: false };
// 	handleOpen = () => this.setState({ open: true });
// 	handleClose = () => this.setState({ open: false });

// 	render() {
// 		const { open } = this.state;

// 		return (
// 			<TransitionablePortal
// 				closeOnTriggerClick
// 				onOpen={this.handleOpen}
// 				onClose={this.handleClose}
// 				openOnTriggerClick
// 				trigger={
// 					<Button
// 						content={open ? "Close Portal" : "Open Portal"}
// 						negative={open}
// 						positive={!open}
// 					/>
// 				}
// 			>
// 				<Segment
// 					style={{ left: "40%", position: "fixed", top: "50%", zIndex: 1000 }}
// 				>
// 					<Header>This is an example portal</Header>
// 					<p>Portals have tons of great callback functions to hook into.</p>
// 					<p>To close, simply click the close button or click away</p>
// 				</Segment>
// 			</TransitionablePortal>
// 		);
// 	}
// }

export default Login;
