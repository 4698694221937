import React from "react";
import { Outlet } from "react-router-dom";
import Info from "./components/Info";
import Profile from "./components/Profile";
import NavBar from "./components/NavBar";
import GamesList from "./containers/GamesList";
import Home from "./components/Home";
import Landing from "./components/Landing";
import SearchGames from "./containers/SearchGames";
import "./App.css";

////////////////////////////////////////
// Okta Setup
import PrivateRoutes from "./components/PrivateRoutes";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Login from "./components/Login";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const ISSUER = process.env.REACT_APP_ISSUER;
const HOST = process.env.REACT_APP_HOST;
// const REDIRECT_URI = `http://${HOST}${CALLBACK_PATH}`; // this comes from env vars
const SCOPES = process.env.REACT_APP_SCOPES;

// // DEBUG env vars
// console.log("ENV process.env.REACT_APP_HOST: ", process.env.REACT_APP_HOST);
// console.log("ENV process.env.REACT_APP_ISSUER: ", process.env.REACT_APP_ISSUER);
// console.log(
// 	"ENV process.env.REACT_APP_CLIENT_ID: ",
// 	process.env.REACT_APP_CLIENT_ID
// );
// console.log(
// 	"ENV process.env.REACT_APP_CALLBACK_PATH: ",
// 	process.env.REACT_APP_CALLBACK_PATH
// );
// console.log(
// 	"ENV process.env.REACT_APP_TESTENVVAR: ",
// 	process.env.REACT_APP_TESTENVVAR
// );

if (!CLIENT_ID || !CALLBACK_PATH || !ISSUER || !HOST) {
	throw new Error("All environmental variables must be set");
}

const config = {
	issuer: ISSUER,
	clientId: CLIENT_ID,
	redirectUri: window.location.origin + CALLBACK_PATH,
	// scopes: SCOPES.split(/\s+/),
};
const oktaAuth = new OktaAuth(config);

/////////////////////////////////////////

function App() {
	// Okta Login functionality
	// const history = useHistory();
	// const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
	// 	history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
	// };

	const navigate = useNavigate();
	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
			replace: true,
		});
	};

	// Application Layout
	const Layout = () => {
		return (
			<div className="page">
				<NavBar />
				<div className="container">
					<div className="contentContainer">
						<Outlet />
					</div>
				</div>
			</div>
		);
	};

	return (
		<Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route element={<Landing />} path="/" exact={true} />
					<Route element={<Home />} path="/home" exact={true} />
					<Route element={<GamesList />} path="/all-games" exact={true} />
					<Route element={<SearchGames />} path="/search-games" exact={true} />
					<Route element={<Profile />} path="/profile" exact={true} />
					<Route element={<PrivateRoutes />}>
						{/* PRIVATE_ROUTES_BEGIN */}
						<Route path="/info" exact={true} element={<Info />} />
						{/* PRIVATE_ROUTES_END */}
					</Route>
					<Route element={<Login />} path="/login" />
					<Route element={<LoginCallback />} path="/login/callback" />
				</Route>
			</Routes>
		</Security>
	);
}

export default App;
