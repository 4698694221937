import React from 'react';

const Home = () => {

    return (
        <div className="home">
            <h1>Home Page </h1>
        </div>
    );
}

export default Home;