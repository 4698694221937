import React from "react";

const Landing = () => {
	return (
		<div className="landing">
			<img
				className="landing-logo"
				alt="landing-banner"
				src={require("./../assets/images/Banner-web-1.png")}
			/>
		</div>
	);
};

export default Landing;
