import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGames } from "../state/actions/gamesListAction";
import GameCard from "../components/GameCard";
import { CardGroup } from "semantic-ui-react";

class GamesList extends Component {
	componentDidMount() {
		// console.log("GamesList component did mount");
		this.props.fetchGames();
	}

	render() {
		return (
			<div className="App">
				<header className="App-header">
					<CardGroup itemsPerRow={4} size="medium" centered stackable>
						{this.props.games.map((gameObj, index) =>
							index === 0 ? null : (
								<div className="cards">
									<GameCard game={gameObj} key={gameObj.id} />
								</div>
							)
						)}
					</CardGroup>
				</header>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		games: state.games.games,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		fetchGames: bindActionCreators(fetchGames, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GamesList);
