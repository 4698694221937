import { useState, useEffect } from "react";
import { hostUrl } from "./HostUrl";

const Info = () => {
	const [infoData, setInfoData] = useState(null);

	useEffect(() => {
		fetch(`${hostUrl()}/info`)
			.then((response) => response.json())
			.then((data) => setInfoData(data))
			.catch((error) => console.error(error));
	}, []);

	return (
		<div className="App">
			<header className="App-header">
				{infoData && (
					<div>
						<h3>Info</h3>
						<p>Profile name: {infoData.name}</p>
						<p>About App: {infoData.about}</p>
						<p>HostUrl: {hostUrl()}</p>
						<p>DatabaseUrl: {infoData.database_url}</p>
					</div>
				)}
			</header>
		</div>
	);
};

export default Info;
