import { useState, useEffect } from "react";
import { hostUrl } from "./HostUrl";

const Profile = () => {
	const [profileData, setProfileData] = useState(null);

	useEffect(() => {
		fetch(`${hostUrl()}/profile`)
			.then((response) => response.json())
			.then((data) => setProfileData(data))
			.catch((error) => console.error(error));
	}, []);

	return (
		<div className="App">
			<header className="App-header">
				{profileData && (
					<div>
						<h3>Profile</h3>
						<p>Profile name: {profileData.name}</p>
						<p>About App: {profileData.about}</p>
						<p>HostUrl: {hostUrl()}</p>
						<p>DatabaseUrl: {profileData.database_url}</p>
					</div>
				)}
			</header>
		</div>
	);
};

export default Profile;
