import { hostUrl } from "../../components/HostUrl";

export function fetchingGames() {
	return {
		type: "FETCHING_GAMES",
	};
}

export function fetchedGames(jsonObject) {
	return {
		type: "FETCHED_GAMES",
		payload: jsonObject,
	};
}

export const fetchGames = () => {
	return (dispatch) => {
		dispatch(fetchingGames());

		return fetch(`${hostUrl()}/all-games`)
			.then((response) => {
				// console.log("fetchGames get response json");
				// console.log("fetchGames response", response);
				return response.json();
			})
			.then((jsonObject) => {
				// console.log("fetchGames get payload as jsonObject");
				// console.log("fetchGames jsonObject", jsonObject);
				dispatch(fetchedGames(jsonObject));
			})
			.catch((error) => {
				console.error("Error fetching games:", error);
			});
	};
};
