import { combineReducers } from "redux";
import gamesListReducer from "./gamesListReducer";
import searchGamesReducer from "./searchGamesReducer";

const rootReducer = combineReducers({
	games: gamesListReducer,
	searchGames: searchGamesReducer,
});

export default rootReducer;
