import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MenuItem, Menu } from "semantic-ui-react";
import { withOktaAuth } from "@okta/okta-react";

export default withOktaAuth(
	class NavBar extends Component {
		constructor(props) {
			super(props);
		}

		state = {};
		handleItemClick = (e, { name }) => this.setState({ activeItem: name });

		render() {
			const { colors } = this.props;
			const { activeItem } = this.state;

			return (
				<Menu color={colors} widths={6}>
					<MenuItem
						name="home"
						active={activeItem === "home"}
						onClick={this.handleItemClick}
						as={Link}
						to="/"
					/>
					<MenuItem
						name="all-games"
						active={activeItem === "all-games"}
						onClick={this.handleItemClick}
						as={Link}
						to="/all-games"
					/>
					<MenuItem
						name="search-games"
						active={activeItem === "search-games"}
						onClick={this.handleItemClick}
						as={Link}
						to="/search-games"
					/>

					<MenuItem
						name="login"
						active={activeItem === "login"}
						onClick={this.handleItemClick}
						as={Link}
						to="/login"
					/>

					{this.props.authState?.isAuthenticated ? (
						<MenuItem
							name="profile"
							active={activeItem === "profile"}
							onClick={this.handleItemClick}
							as={Link}
							to="/profile"
						/>
					) : null}
					{this.props.authState?.isAuthenticated ? (
						<MenuItem
							name="info"
							active={activeItem === "info"}
							onClick={this.handleItemClick}
							as={Link}
							to="/info"
						/>
					) : null}
				</Menu>
			);
		}
	}
);
