import React, { Component } from "react";
import {
	CardHeader,
	CardMeta,
	CardDescription,
	CardContent,
	Card,
	Button,
	Icon,
	Image,
} from "semantic-ui-react";

class GameCardSearchItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	toggleDescription = () => {
		this.setState((prevState) => ({
			expanded: !prevState.expanded,
		}));
	};

	generateGameCard = () => {
		const { expanded } = this.state;

		return (
			<Card raised className="gamecard-searchitem">
				<Image
					as="a"
					href={"https://boardgamegeek.com/boardgame/" + this.props.game.id}
					src={this.props.game.image}
					wrapped
					ui={false}
					size="small"
				/>
				<CardContent>
					<CardHeader>
						<div className="title">{this.props.game.title}</div>
						<div className="rating">
							<span className="rating-text">Rating: </span>
							<span className="rating-value">
								{Number(this.props.game.bayesaverage.toFixed(1))}
							</span>
						</div>
					</CardHeader>
					{/* <CardMeta>
						<span className="nrate">{this.props.game.nrate}</span>
					</CardMeta> */}
					{/* <CardMeta>Bayes Rating: {this.props.game.bayesaverage}</CardMeta> */}
					<CardMeta></CardMeta>

					{/* <CardDescription>
						{expanded ? (
							// <p>{this.props.game.description}</p>
							<p>{this.props.game.full_description}</p>
						) : (
							// <p>{this.props.game.description.slice(0, 100)}...</p>
							<p>{this.props.game.full_description.slice(0, 100)}...</p>
						)}
						<Button basic color="olive" onClick={this.toggleDescription}>
							<Icon name={expanded ? "angle up" : "angle down"} />
							{expanded ? "Show less" : "Show more"}
						</Button>
					</CardDescription> */}
				</CardContent>
				<CardContent extra>
					<div className="ui buttons">
						<Button
							basic
							color="red"
							content="Like"
							icon="heart"
							label={{
								basic: true,
								color: "black",
								pointing: "left",
								content: "0",
							}}
						>
							Like
						</Button>
					</div>
				</CardContent>
			</Card>
		);
	};

	render() {
		// console.log("this.generateGameCard(): ", this.generateGameCard());
		return <div>{this.generateGameCard()}</div>;
	}
}

export default GameCardSearchItem;
