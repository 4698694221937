import {
	SEARCH_GAMES_START,
	SEARCH_GAMES_SUCCESS,
	SEARCH_GAMES_FAILURE,
	UPDATE_SELECTION,
	REMOVE_SELECTION,
} from "../actions/searchGamesAction";

const initialState = {
	games: [],
	isLoading: false,
	error: null,
	// selection: "",
	selectedItem: null,
	selectedItems: [],
};

export default function searchGamesReducer(state = initialState, action) {
	switch (action.type) {
		case SEARCH_GAMES_START:
			// console.log("SEARCH_GAMES_START state: ", state);
			return {
				...state,
				isLoading: true,
			};
		case SEARCH_GAMES_SUCCESS:
			// console.log("SEARCH_GAMES_SUCCESS state: ", state);

			return {
				...state,
				isLoading: false,
				games: action.payload,
			};
		case SEARCH_GAMES_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case UPDATE_SELECTION:
			return {
				...state,
				selectedItem: action.selectedItem,
				selectedItems: action.selectedItems,
			};
		case REMOVE_SELECTION:
			return {
				...state,
				selectedItem: action.selectedItem,
				selectedItems: action.selectedItems,
			};
		default:
			return state;
	}
}
