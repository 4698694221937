import { hostUrl } from "../../components/HostUrl";
import axios from "axios";

export const SEARCH_GAMES_START = "SEARCH_GAMES_START";
export const SEARCH_GAMES_SUCCESS = "SEARCH_GAMES_SUCCESS";
export const SEARCH_GAMES_FAILURE = "SEARCH_GAMES_FAILURE";
export const UPDATE_SELECTION = "UPDATE_SELECTION";
export const REMOVE_SELECTION = "REMOVE_SELECTION";

export const searchGames = (query) => async (dispatch) => {
	dispatch({
		type: SEARCH_GAMES_START,
	});

	try {
		const response = await axios.get(`${hostUrl()}/search?q=${query}`);
		dispatch({
			type: SEARCH_GAMES_SUCCESS,
			payload: response.data,
		});
	} catch (error) {
		dispatch({
			type: SEARCH_GAMES_FAILURE,
			payload: error.message,
		});
	}
};

export const updateSelection = (state) => async (dispatch) => {
	console.log("searchGamesAction updateSelection state: ", state);

	dispatch({
		type: UPDATE_SELECTION,
		selectedItem: state.selectedItem,
		selectedItems: state.selectedItems,
	});
};

export const removeSelection = (state) => async (dispatch) => {
	console.log("searchGamesAction removeSelection state: ", state);

	dispatch({
		type: REMOVE_SELECTION,
		selectedItem: state.selectedItem,
		selectedItems: state.selectedItems,
	});
};
