import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GameCard from "../components/GameCard";
import GameCardSearchItem from "../components/GameCardSearchItem";
import {
	searchGames,
	updateSelection,
	removeSelection,
} from "../state/actions/searchGamesAction";
import {
	Grid,
	GridColumn,
	Search,
	SearchResult,
	Button,
	Icon,
	CardGroup,
} from "semantic-ui-react";

class SearchGames extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			games: [],
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleResultSelect = this.handleResultSelect.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.games.length !== this.props.games.length) {
			this.setState({ games: this.props.games });
		}

		// console.log("SearchGames componentDidUpdate this.state: ", this.state);
		// console.log("SearchGames componentDidUpdate this.props: ", this.props);
	}

	removeSelectedItem = (e, data) => {
		// console.log("SearchGames removeSelectedItem e: ", e);
		// console.log("SearchGames removeSelectedItem data: ", data);

		this.setState(
			(previousState) => ({
				selectedItems: this.props.selectedItems.filter(function (item) {
					return item.id !== data.gameid;
				}),
			}),
			() => {
				this.props.removeSelection(this.state);
			}
		);
	};

	handleChange = (e, data) => {
		// console.log("SearchGames handleChange e: ", e);
		// console.log("SearchGames handleChange data: ", data);

		this.setState({ search: e.target.value });
		if (e.target.value.length >= 1) {
			this.props.searchGames(e.target.value);
		}
	};

	handleResultSelect = (e, data) => {
		// console.log("SearchGames handleResultSelect e: ", e);
		// console.log("SearchGames handleResultSelect data: ", data);

		let alreadySelected = this.props.selectedItems.some(
			(item) => data.result.id === item.id
		);
		// console.log(
		// 	"SearchGames handleResultSelect alreadySelected: ",
		// 	alreadySelected
		// );

		if (!alreadySelected) {
			this.setState(
				(previousState) => ({
					selection: data.result.name,
					selectedItem: data.result,
					selectedItems: [...this.props.selectedItems, data.result],
				}),
				() => {
					this.props.updateSelection(this.state);
				}
			);
		}
	};

	render() {
		return (
			<Grid>
				<GridColumn width={6}>
					<Search
						loading={this.props.loading}
						placeholder="Search Games..."
						onSearchChange={this.handleChange}
						onResultSelect={this.handleResultSelect}
						results={this.state.games}
						value={this.state.search}
					>
						{/* <SearchResult title="test title" /> */}
					</Search>
				</GridColumn>

				<GridColumn width={10}>
					<CardGroup itemsPerRow={2} size="medium" centered stackable>
						{this.props.selectedItems.map((gameObj, index) => (
							<div className="search-cards" key={gameObj.id}>
								<Button
									basic
									gameid={gameObj.id}
									color="red"
									onClick={this.removeSelectedItem}
								>
									<Icon name={"remove circle"} />
									Remove
								</Button>
								{/* <GameCard game={gameObj} key={gameObj.id} /> */}
								<GameCardSearchItem game={gameObj} />
							</div>
						))}
					</CardGroup>
				</GridColumn>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		games: state.searchGames.games,
		loading: state.searchGames.loading,
		selectedItem: state.searchGames.selectedItem,
		selectedItems: state.searchGames.selectedItems,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		searchGames: (query) => dispatch(searchGames(query)),
		updateSelection: bindActionCreators(updateSelection, dispatch),
		removeSelection: bindActionCreators(removeSelection, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchGames);
