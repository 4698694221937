const gamesReducer = (
	state = {
		isLoading: false,
		games: [],
	},
	action
) => {
	switch (action.type) {
		case "FETCHING_GAMES":
			return {
				...state,
				isLoading: true,
			};

		case "FETCHED_GAMES":
			// console.log("FETCHED_GAMES state", state);
			// console.log("FETCHED_GAMES action", action);
			return {
				...state,
				games: action.payload,
			};

		default:
			return state;
	}
};

export default gamesReducer;
