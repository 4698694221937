import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./state/reducers/reducers";

export const store = configureStore({
	reducer: rootReducer,
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App tab="home" />
		</BrowserRouter>
	</Provider>
);
